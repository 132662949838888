<template>
  <b-row>
    <b-col md="7">
      <b-card
        text-variant="center"
        class="card card-congratulations"
      >
        <!-- images -->
        <b-img
          :src="require('@/assets/images/elements/decore-left.png')"
          class="congratulations-img-left"
        />
        <b-img
          :src="require('@/assets/images/elements/decore-right.png')"
          class="congratulations-img-right"
        />
        <!--/ images -->

        <b-avatar
          variant="primary"
          size="70"
          class="shadow mb-2"
        >
          <feather-icon
            size="28"
            icon="AwardIcon"
          />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">
          Bienvenue sur e-Certificat,
        </h1>
        <b-card-text class="m-auto w-75">
          {{ user.name }} <br> <strong> {{ user.role }}</strong> .
        </b-card-text>
      </b-card>

      <b-row>
        <b-col md="6">
          <statistic-card-horizontal
            icon="CheckIcon"
            color="warning"
            :statistic="statistiques.totalRequestCertificateAwaitingSignatureByMairie"
            statistic-title="Demandes En Attente de signature"
          />
        </b-col>
        <b-col
          md="6"
          class=" py-0 my-0"
        >
          <statistic-card-horizontal
            icon="CheckIcon"
            color="success"
            :statistic="statistiques.totalRequestCertificateSignedByMairie"
            statistic-title="Demandes Signées"
          />
        </b-col>

      </b-row>
    </b-col>
    <b-col md="5">
      <b-card
        no-body
        style="height:450px"
      >
        <b-card-header>
          <b-card-title>Statistiques des demandes</b-card-title>
          <b-dropdown
            variant="transparent"
            class="chart-dropdown "
            right
            no-caret
            toggle-class="p-0 mb-25"
            size="sm"
          >
            <b-dropdown-item
              v-for="day in chartData.lastDays"
              :key="day"
            >
              {{ day }}
            </b-dropdown-item>
          </b-dropdown>
        </b-card-header>

        <b-card-body>

          <!-- apex chart -->
          <vue-apex-charts
            type="pie"
            height="250"
            class="mt-2 mb-1"
            :options="customersPie.chartOptions"
            :series="customersPie.series"
          />

          <!-- list group -->
          <div class="pt-25">
            <div
              v-for="(data,index) in chartData.listData"
              :key="index"
              class="d-flex justify-content-between"
              :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
            >
              <div class="series-info">
                <feather-icon
                  :icon="data.icon"
                  size="16"
                  class="mr-50"
                  :class="data.iconColor"
                />
                <span class="font-weight-bolder">{{ data.text }}</span>
              </div>
              <span>{{ data.result }}</span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BCardText, BAvatar, BRow, BCol, BImg,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { $themeColors } from '@themeConfig'
import {
  ref, onBeforeMount,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useStatistiques from '@/services/statistiqueService'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BRow,
    BImg,
    BCol,
    StatisticCardHorizontal,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  setup() {
    const { statistiquesSuperAdmin, statistiques } = useStatistiques()
    const user = JSON.parse(store.state.authStore.user)
    const chartData = ref([])
    const customersPie = ref([])
    onBeforeMount(async () => {
      await statistiquesSuperAdmin()
      customersPie.value = {
        series: [statistiques.totalRequestCertificateAwaitingSignatureByMairie, statistiques.totalRequestCertificateSignedByMairie],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Demandes en attente', 'Demandes signées'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.warning, $themeColors.success],
        },
      }
      chartData.value = {
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        listData: [
          {
            icon: 'CircleIcon',
            iconColor: 'text-warning',
            text: 'Demandes en attente',
            result: statistiques.totalRequestCertificateAwaitingSignatureByMairie,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-success',
            text: 'Demandes Signées',
            result: statistiques.totalRequestCertificateSignedByMairie,
          },
        ],
      }
      console.clear()
    })
    return {
      chartData,
      customersPie,
      statistiques,
      user,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
